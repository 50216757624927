<template>
  <div>
    <trac-loading v-if="loading" />
    <trac-heading-info title="Options">
      <CreateOptions
        :show="togggleCreateModal"
        :selected-option="selectedOption"
        @click="
          () => {
            selectedOption = {};
            togggleCreateModal = false;
          }
        "
        @close="fetchOptions"
      />
    </trac-heading-info>

    <div class="bg-white mt-12 px-6 py-6 big-shadow">
      <div class="flex mb-6 justify-between items-center">
        <div class="w-1/2">
          <div
            class="flex flex-row bg-white items-center rounded justify-between"
          >
            <img src="@/assets/svg/Search_icon.svg" class="mr-3" />
            <span class="text-xs font-light text-primaryGray w-full">
              <input
                class="w-full py-2 outline-none font-bold"
                type="text"
                name=""
                id=""
                v-model="search"
                placeholder="Search option..."
              />
            </span>
          </div>
        </div>
      </div>

      <table class="w-full table-auto">
        <thead class="bg-blue-100">
          <tr>
            <th
              v-for="{ name, classValue } in tableHeaders"
              :key="name"
              class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
              :class="classValue"
            >
              {{ name }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!options.length">
          <tr>
            <td colspan="7">
              <div
                class="flex justify-center items-center w-full h-full bg-transparent"
              >
                <div class="h-40 flex items-center text-lg text-gray-600">
                  No options added yet.
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            class="cursor-pointer hover:bg-gray-200"
            v-for="(option, index) in paginatedOptions"
            :key="option._id"
          >
            <td class="text-xs whitespace-no-wrap p-4">
              {{ page * limit - limit + (index + 1) }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ option.key }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ joinValues(option.values) }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              <div class="relative" v-click-outside="reset">
                <button
                  @click.stop
                  @click="currentTagIndex = index"
                  class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-4 rounded inline-flex justify-between capitalize items-center cursor-pointer w-full mb-2"
                >
                  Actions
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="fill-current h-4 w-4"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </button>
                <div
                  v-if="currentTagIndex === index"
                  class="absolute right-0 w-32 bg-white rounded-md shadow-xl z-20"
                >
                  <button
                    v-for="name in ['Edit', 'Delete']"
                    :key="name"
                    class="block w-full text-left px-4 py-2 text-xs text-gray-700 hover:bg-gray-500 hover:text-white"
                    @click="toggleAction({ name, option })"
                  >
                    {{ name }}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!loading" class="flex justify-center mt-4">
        <trac-button
          :disabled="
            displayedOptions.length >=
            (search ? paginatedOptions.length : options.length)
          "
          @button-clicked="loadMoreOptions"
        >
          {{
            displayedOptions.length >=
            (search ? paginatedOptions.length : options.length)
              ? "No More Items"
              : "Load More"
          }}
        </trac-button>
      </div>
    </div>

    <DeleteOptions
      :view="deleteView"
      :selected-option="selectedOption"
      @update-view="deleteView = $event"
      @refetch-options="
        () => {
          fetchOptions();
          deleteView = '';
        }
      "
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import CreateOptions from "./CreateOptions.vue";
import DeleteOptions from "./DeleteOptions.vue";
import { mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import Pagination from "vue-pagination-2";
import moment from 'moment';

export default {
  components: {
    CreateOptions,
    DeleteOptions,
    Pagination,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      limit: 20,
      page: 1,
      search: "",
      loading: false,
      tableHeaders: [
        {
          name: "No.",
          classValue: "w-1/6",
        },
        {
          name: "Name",
          classValue: "w-2/6",
        },
        {
          name: "Options",
          classValue: "w-2/6",
        },
        {
          name: "Actions",
          classValue: "w-1/6",
        },
      ],
      options: [],
      currentTagIndex: null,
      selectedOption: {},
      togggleCreateModal: false,
      deleteView: "",
    };
  },
  created() {
    this.fetchOptions();
  },
  computed: {
    displayedOptions() {
      return this.options.sort((a, b) => moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1).filter(
        ({ key, values }) =>
          key.toLowerCase().match(this.search.toLowerCase()) ||
          values.some((value) =>
            value.toLowerCase().match(this.search.toLowerCase())
          )
      );
    },
    paginatedOptions() {
      const from = (this.page - 1) * this.limit;
      const to = from + this.limit;
      return this.displayedOptions?.slice(from, to);
    },
  },
  methods: {
    ...mapActions(["FETCH_OPTIONS"]),
    reset() {
      this.currentTagIndex = null;
    },
    joinValues(values) {
      if (!values) return null;

      if (values.length <= 3) {
        return values.join(", ");
      }

      return `${values.slice(0, 3).join(", ")} + ${
        values.slice(3).length
      } other${values.slice(3).length > 1 ? "s" : ""}`;
    },
    async fetchOptions() {
      this.loading = true;
      try {
        const res = await this.FETCH_OPTIONS();
        this.options = res.data ?? [];
      } catch (error) {
        eventBus.$emit("trac-alert", { message: error.response.data.message });
      } finally {
        this.loading = false;
      }
    },
    toggleAction({ name, option }) {
      this.selectedOption = { ...option };

      if (name === "Edit") {
        this.togggleCreateModal = true;
        return;
      }

      this.deleteView = "confirm-delete";
    },
    loadMoreOptions() {
      this.page = 1;
      this.limit = this.limit + 10;
    },
  },
};
</script>

<style scoped></style>
