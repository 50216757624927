<template>
  <div>
    <trac-loading v-if="loading" />
    <trac-modal v-if="view === 'confirm-delete'">
      <div class="mx-10 my-8 text-center">
        <span class="font-semibold text-xl">
          Are you sure you want to delete the option
          <span class="capitalize">"{{ selectedOption.key }}"</span>?
        </span>
        <div class="flex flex-row w-8/12 justify-between mx-auto mt-16">
          <trac-button
            variant="outline"
            id="cancel-btn"
            @button-clicked="$emit('update-view', '')"
          >
            Cancel
          </trac-button>
          <!-- @button-clicked="deleteCategory" -->
          <trac-button
            class="text-secondaryRed border-secondaryRed"
            variant="outline"
            id="delete-btn"
            @button-clicked="deleteOptions"
          >
            Delete
          </trac-button>
        </div>
      </div>
    </trac-modal>

    <trac-modal
      v-if="view === 'delete-success'"
      @close="$emit('refetch-options')"
    >
      <div class="flex flex-col items-center gap-8 mb-6">
        <img src="@/assets/svg/success-2.svg" alt="success-icon" />
        <p>Options has been deleted successfully</p>
        <trac-button
          class="w-3/5"
          id="clickToAdd"
          @button-clicked="$emit('refetch-options')"
        >
          CLOSE
        </trac-button>
      </div>
    </trac-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { eventBus } from "../../../eventBus";

export default {
  emits: ["update-view", "refetch-options"],
  props: {
    view: {
      type: String,
      default: "",
    },
    selectedOption: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false,
      toggleDeleteModal: false,
    };
  },
  methods: {
    ...mapActions(["DELETE_OPTIONS"]),
    async deleteOptions() {
      this.loading = true;
      try {
        const res = await this.DELETE_OPTIONS(this.selectedOption?._id);
        res?.status && this.$emit("update-view", "delete-success");
      } catch (error) {
        eventBus.$emit("trac-alert", { message: error.response.data.message });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
